import React, { useEffect } from "react";
import "./fixes.css";
export const SupportWidget = ()=>{
    useEffect(()=>{
        window.chatwootSettings = {
            hideMessageBubble: true,
            showPopoutButton: false,
            position: "right",
            locale: "en",
            type: "standard",
            darkMode: "auto"
        };
        const BASE_URL = process.env.NEXT_PUBLIC_CHATWOOT_BASE_URL;
        const TOKEN = process.env.NEXT_PUBLIC_CHATWOOT_TOKEN;
        if (BASE_URL && TOKEN) {
            let g = document.createElement("script");
            let s = document.getElementsByTagName("script")[0];
            g.src = BASE_URL + "/packs/js/sdk.js";
            s.parentNode.insertBefore(g, s);
            g.async = !0;
            g.onload = function() {
                const sdk = window.chatwootSDK;
                if (sdk) {
                    sdk.run({
                        websiteToken: TOKEN,
                        baseUrl: BASE_URL
                    });
                }
            };
        }
    }, []);
    return null;
};
export const setSupportWidgetUser = (user)=>{
    if (window.$chatwoot != undefined) {
        try {
            window.$chatwoot.setUser(user.id, {
                email: user.email
            });
        } catch  {}
    }
};
export const hideWidget = ()=>{
    if (window.$chatwoot != undefined) {
        try {
            window.$chatwoot.hideMessageBubble = true;
            window.$chatwoot.showPopoutButton = false;
        } catch  {}
    }
};
export const showWidget = ()=>{
    if (window.$chatwoot != undefined) {
        try {
            window.$chatwoot.toggleBubbleVisibility("show");
        } catch  {}
    }
};
export const openWidget = ()=>{
    if (window.$chatwoot != undefined) {
        window.$chatwoot.toggle("open");
    }
};
export const setSupportWidgetAttributes = (hasPaidPlan)=>{
    if (window.$chatwoot != undefined) {
        try {
            window.$chatwoot.setCustomAttributes({
                hasPaidPlan: hasPaidPlan
            });
        } catch  {}
    }
};
