import { removeTrailingSlash } from "./navigation";
export const getLocaleValue = (locale, defaultLocale)=>{
    return locale == defaultLocale ? "" : locale;
};
export const getLocalizedPath = (locale, defaultLocale)=>{
    const localeParam = getLocaleValue(locale, defaultLocale);
    return localeParam == "" ? "" : `/${localeParam}`;
};
export const getLocalizedUrl = (rootUrl, locale, defaultLocale)=>{
    return `${removeTrailingSlash(rootUrl)}${getLocalizedPath(locale, defaultLocale)}`;
};
export const getPathWithoutCurrentLocale = (pathName, currentLocale)=>{
    return (pathName == null ? void 0 : pathName.endsWith(currentLocale)) ? pathName == null ? void 0 : pathName.replace(`${currentLocale}`, "") : pathName == null ? void 0 : pathName.replace(`${currentLocale}/`, "");
};
export const getLanguages = (rootUrl, path, locales, defaultLocale)=>{
    const languages = locales.map((locale)=>{
        return {
            locale: locale,
            url: `${getLocalizedUrl(rootUrl, locale, defaultLocale)}${path}`
        };
    });
    // add x-default locale
    languages.push({
        locale: "x-default",
        url: `${getLocalizedUrl(rootUrl, defaultLocale, defaultLocale)}${path}`
    });
    return languages;
};
