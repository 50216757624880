'use client'

import { CacheProvider } from '@chakra-ui/next-js'
import { ChakraProvider } from '@chakra-ui/react'
import { TrackersProvider } from '@astrolab/web/lib/tracking/tracking'
import theme from '@astrolab/web/theme/theme'

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <TrackersProvider>
      <CacheProvider>
        <ChakraProvider theme={theme}>{children}</ChakraProvider>
      </CacheProvider>
    </TrackersProvider>
  )
}
