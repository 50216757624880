import { locales } from "../constants";
import { createSharedPathnamesNavigation } from "next-intl/navigation";
export const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation({
    locales
});
export const removeTrailingSlash = (path)=>{
    return path.endsWith("/") ? path.slice(0, -1) : path;
};
export const removeLeadingSlash = (path)=>{
    return path.startsWith("/") ? path.slice(1) : path;
};
