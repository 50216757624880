import * as Sentry from "@sentry/nextjs";
export class Trackers {
    constructor(posthog){
        this.posthog = posthog;
    }
    capture(name, properties) {
        try {
            // https://posthog.com/docs/libraries/js#capturing-events
            this.posthog.capture(name, properties);
        } catch (error) {
            try {
                Sentry.captureMessage(`Failed to capture PostHog event: ${error}`);
            } catch (error) {
                console.error(`Failed to send sentry error: ${error}`);
            }
        }
    }
    identify(new_distinct_id, userPropertiesToSet, userPropertiesToSetOnce) {
        // https://posthog.com/docs/libraries/js#identifying-users
        this.posthog.identify(new_distinct_id, userPropertiesToSet, userPropertiesToSetOnce);
    }
    reset(reset_device_id) {
        // https://posthog.com/docs/libraries/js#reset-after-logout
        this.posthog.reset(reset_device_id);
    }
    register(properties, days) {
        // https://posthog.com/docs/libraries/js#super-properties
        this.posthog.register(properties, days);
    }
    register_once(properties, default_value, days) {
        // https://posthog.com/docs/libraries/js#super-properties
        this.posthog.register_once(properties, default_value, days);
    }
    unregister(property) {
        // https://posthog.com/docs/libraries/js#removing-stored-super-properties
        this.posthog.unregister(property);
    }
    isFeatureEnabled(key, options) {
        // https://posthog.com/docs/libraries/js#boolean-feature-flags
        return this.posthog.isFeatureEnabled(key, options);
    }
    getFeatureFlag(key, options) {
        // https://posthog.com/docs/libraries/js#multivariate-feature-flags
        return this.posthog.getFeatureFlag(key, options);
    }
    onFeatureFlags(callback) {
        // https://posthog.com/docs/libraries/js#ensuring-flags-are-loaded-before-usage
        return this.posthog.onFeatureFlags(callback);
    }
    group(groupType, groupKey, groupPropertiesToSet) {
        // https://posthog.com/docs/libraries/js#group-analytics
        return this.posthog.group(groupType, groupKey, groupPropertiesToSet);
    }
    updateEarlyAccess(key, isEnrolled // false to enroll out
    ) {
        // https://posthog.com/docs/libraries/js#early-access-feature-management
        this.posthog.updateEarlyAccessFeatureEnrollment(key, isEnrolled);
    }
    reloadFeatureFlags() {
        // https://posthog.com/docs/libraries/js#reloading-feature-flags
        this.posthog.reloadFeatureFlags();
    }
    renderSurvey(surveyId, selector) {
        this.posthog.renderSurvey(surveyId, selector);
    }
}
